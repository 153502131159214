<template>
  <div class="h-screen relative">
    <content-holder
      v-if="canViewReports"
      :title="'Revenue Summary'"
      class="absolute inset-0 p-6"
    >
      <div
        class="items-center flex-wrap mt-4 space-y-4 w-full dateRange md:flex md:flex-row md:items-center md:space-y-5 lg:space-y-0 lg:space-x-4 sm:mt-6 sm:order-first sm:mr-8"
      >
        <div
          class="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-10 md:space-x-6 mb-6 sm:mb-0"
        >
          <date-picker
            :newDate="selectedDates.startDate"
            :title="'Start'"
            @updateDate="updateStartDate"
          >
          </date-picker>
          <date-picker
            :newDate="selectedDates.endDate"
            :title="'End'"
            @updateDate="updateEndDate"
          ></date-picker>
        </div>

        <select
          v-model="reportType"
          class="block w-full md:w-64 text-gray-700 font-medium py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
          name="payment_type"
        >
          <option value="">Select a payment type</option>
          <option value="payment">Payment</option>
          <option value="settlement">Settlement</option>
        </select>

        <button
          id="btnLoad"
          type="button"
          :disabled="loadingReportsData"
          @click="update"
          class="flex-none w-full md:w-64 lg:w-auto px-8 py-2 ml-0 md:ml-5 lg:ml-0 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
        >
          <div v-if="!loadingReportsData">Load data</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>
      <app-summary :date="selectedDates" :type="reportType"></app-summary>
      <div
        class="w-full mt-4 grid grid-cols-1 sm:grid-cols-2 border-b border-gray-50 pb-10 shadow-sm"
      >
        <!--        <div class="custom_chart"></div>-->
        <div class="mt-8 overflow-scroll sm:overflow-hidden hide-scrollbar">
          <apexchart
            class="w-102 sm:w-full"
            width="100%"
            height="300"
            type="bar"
            :options="monthsChart.options"
            :series="monthsChart.series"
          ></apexchart>
        </div>
        <div class="my-8 overflow-scroll sm:overflow-hidden hide-scrollbar">
          <apexchart
            class="w-102 sm:w-full"
            width="100%"
            height="300"
            type="bar"
            :options="banksChart.options"
            :series="banksChart.series"
          ></apexchart>
        </div>

        <div class="relative">
          <div class="channels_chart w-full"></div>
          <div
            class="w-full flex justify-end md:justify-start items-end mb-10 md:mb-0 md:mt-4"
          >
            <button
              @click="generateChannelsReport"
              class="flex items-center justify-center text-center w-24 md:w-36 md:px-4 py-2 mt-6 font-semibold text-white text-xs md:text-base xl:text-lg transition duration-500 ease-in-out transform rounded-lg bg-gradient-to-r from-blue-400 hover:from-blue-500 to-blue-500 hover:to-blue-400 focus:ring focus:ring-blue-400 focus:outline-none"
            >
              <div v-if="!loadingChannelsReport" class="text-center">
                Load Report
              </div>
              <div v-else class="flex items-center space-x-4 justify-center">
                <i class="fas fa-circle-notch animate-spin mr-4"></i> Loading...
              </div>
            </button>
          </div>
        </div>

        <div id="topPayers-holder" class="sm:container mx-auto w-full sm:px-4">
          <div
            class="py-5 px-4 w-full border dark:bg-gray-800 bg-white mb-2 rounded-md"
          >
            <div class="flex sm:flex-row flex-col items-start justify-between">
              <div class="flex flex-row items-center">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                >
                  Top payers
                </h3>
                <select
                  v-model="topPayersLimit"
                  class="block w-auto ml-4 text-gray-700 py-1 px-1 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                  name="topPayers"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
                </select>
              </div>
              <button
                type="button"
                @click.prevent="exportToExcel"
                :disabled="exportingExcel"
                class="px-2 py-1 sm:h-auto w-full sm:w-auto mt-4 sm:mt-0 mb-2 sm:mb-0 font-medium text-white text-sm transition duration-500 ease-in-out transform rounded shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
              >
                <div v-if="!exportingExcel">Export excel</div>
                <div v-else class="flex items-center space-x-4 justify-center">
                  <i class="fas fa-circle-notch animate-spin"></i>
                  <span>Please wait...</span>
                </div>
              </button>

              <!-- <download-excel fileName="topPayers" buttonText="Download" :jsonData="topPayersWithLimit" /> -->
              <!-- <ExcelComponent class="excel" :data="topPayersWithLimit" :fields="topPayersFields" worksheet="Top payers"
                name="Top Payers" type="xls">
                Download
              </ExcelComponent> -->
            </div>
            <p class="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-200">
              Details about top payers across channels.
            </p>
          </div>

          <table id="topPayers" class="hidden">
            <tr>
              <th>Customer Name</th>
              <th>Bank</th>
              <th>Amount</th>
            </tr>
            <tr v-for="payer in topPayersWithLimit" :key="payer">
              <td>{{ payer.customer_name }}</td>
              <td>{{ payer.bank }}</td>
              <td>₦{{ formatAmount(payer.Amount) }}</td>
            </tr>
          </table>

          <ul
            id="list-topPayers"
            class="w-full flex flex-col overflow-y-auto h-56 lg:h-72 xl:h-96 border-b"
          >
            <li
              v-for="payer in topPayersWithLimit"
              :key="payer"
              class="w-full border-gray-400 flex flex-row mb-2 animate__animated animate__slideInLeft"
            >
              <div
                class="w-full transition duration-100 ease-linear transform hover:shadow-md border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-col sm:flex-row sm:flex-1 sm:items-center px-4 py-2"
              >
                <div class="flex-1 pl-1 md:mr-16">
                  <div
                    class="font-medium dark:text-white text-gray-600 text-sm"
                  >
                    {{ payer.customer_name }}
                  </div>
                  <div class="text-gray-500 dark:text-gray-200 text-xs">
                    {{ payer.bank }}
                  </div>
                </div>
                <div
                  class="text-gray-700 dark:text-gray-200 text-sm font-medium mt-2 sm:mt-0"
                >
                  ₦{{ formatAmount(payer.Amount) }}
                </div>
              </div>
            </li>
          </ul>
          <button
            @click="generateDetailedReport"
            class="flex items-center w-full px-8 py-2 mt-6 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
          >
            <div
              v-if="!loadingDetailedReport"
              class="flex items-center justify-between w-full"
            >
              Generate detailed report
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="ml-auto w-4 h-4"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
        </div>
      </div>
      <div
        class="mt-8 shadow-sm overflow-scroll sm:overflow-hidden hide-scrollbar py-10"
      >
        <apexchart
          class="w-102 sm:w-full"
          width="100%"
          height="400"
          type="bar"
          :options="revenueItemChart.options"
          :series="revenueItemChart.series"
        ></apexchart>
      </div>
      <div
        class="mt-8 shadow-sm overflow-scroll sm:overflow-hidden hide-scrollbar py-10"
      >
        <apexchart
          class="w-102 sm:w-full"
          width="100%"
          height="300"
          type="bar"
          :options="mdaChart.options"
          :series="mdaChart.series"
        ></apexchart>
      </div>

      <div class="overflow-hidden text-gray-600 body-font">
        <div class="py-16 px-1 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="p-4 w-full xl:w-1/2 md:w-1/2">
              <div
                class="flex relative flex-col p-6 rounded-lg border border-blue-300 bg-gradient-to-r from-blue-100 to-gray-50"
              >
                <div
                  class="flex sm:flex-row flex-col sm:justify-between sm:items-center items-start overflow-hidden"
                >
                  <h2
                    class="mb-1 text-sm font-medium tracking-widest title-font ml-2"
                  >
                    MDA
                  </h2>

                  <div class="flex">
                    <app-drop-down
                      :items="Mdas"
                      :title="selectedMda.substr(0, 50)"
                      :classList="[
                        ' w-full sm:w-96 text-xs sm:text-md rounded border border-gray-300 text-gray-dark ',
                      ]"
                      :color="'gray'"
                      @item-selected="selectMda"
                      :key="selectedMda"
                    >
                    </app-drop-down>
                  </div>
                </div>
                <div class="overflow-hidden overflow-x-auto">
                  <div
                    class="w-102 sm:w-full mt-8 shadow-sm overflow-scroll sm:overflow-hidden hide-scrollbar"
                  >
                    <apexchart
                      width="100%"
                      height="250"
                      type="area"
                      :options="singleMdaChart.options"
                      :series="singleMdaChart.series"
                    >
                    </apexchart>
                  </div>
                </div>
                <button
                  @click="generateReport('MDA')"
                  class="flex items-center w-full sm:w-auto px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
                >
                  Generate report
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="ml-auto w-4 h-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="p-4 w-full xl:w-1/2 md:w-1/2">
              <div
                class="flex relative flex-col p-6 rounded-lg border border-gray-300 bg-gradient-to-r to-blue-100 from-gray-50"
              >
                <div
                  class="flex sm:flex-row flex-col sm:justify-between sm:items-center items-start"
                >
                  <h2
                    class="mb-1 text-sm font-medium tracking-widest title-font ml-2"
                  >
                    Bank
                  </h2>

                  <div class="flex w-full sm:w-3/4">
                    <app-drop-down
                      :items="Banks"
                      :title="selectedBank"
                      :classList="[
                        ' w-full sm:w-96 text-xs sm:text-md rounded border border-gray-300 text-gray-dark ',
                      ]"
                      :color="'gray'"
                      @item-selected="selectBank"
                      :key="selectedBank"
                    >
                    </app-drop-down>
                  </div>
                </div>
                <div class="overflow-hidden overflow-x-auto">
                  <div
                    class="w-102 sm:w-full mt-8 shadow-sm overflow-scroll sm:overflow-hidden hide-scrollbar"
                  >
                    <apexchart
                      width="100%"
                      height="250"
                      type="area"
                      :options="singleBankChart.options"
                      :series="singleBankChart.series"
                    >
                    </apexchart>
                  </div>
                </div>
                <button
                  @click="generateReport('Bank')"
                  class="flex items-center w-full sm:w-auto px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
                >
                  Generate report
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="ml-auto w-4 h-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-hidden text-gray-600 body-font">
        <div class="px-1 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="p-4 w-full">
              <div
                class="flex relative flex-col p-6 rounded-lg border border-blue-300 bg-gradient-to-r from-blue-100 to-gray-50"
              >
                <div
                  class="flex sm:flex-row flex-col sm:justify-between sm:items-center items-start"
                >
                  <h2
                    class="mb-1 text-sm font-medium tracking-widest title-font sm:mr-12 ml-2"
                  >
                    Item
                  </h2>

                  <div class="relative w-full">
                    <app-drop-down
                      :items="RevItems"
                      :title="selectedItem"
                      :classList="[
                        ' w-full  rounded text-xs sm:text-md border border-gray-300 text-gray-dark ',
                      ]"
                      :color="'gray'"
                      @item-selected="selectItem.revenue_item"
                      :key="selectedItem"
                    >
                    </app-drop-down>
                  </div>
                </div>
                <div class="overflow-hidden overflow-x-auto">
                  <div
                    class="w-102 sm:w-full mt-8 shadow-sm overflow-scroll sm:overflow-hidden hide-scrollbar"
                  >
                    <apexchart
                      width="100%"
                      height="250"
                      type="area"
                      :options="singleItemChart.options"
                      :series="singleItemChart.series"
                    >
                    </apexchart>
                  </div>
                </div>
                <button
                  @click="generateReport('Revenue Item')"
                  class="flex items-center w-full sm:w-auto px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
                >
                  Generate report
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="ml-auto w-4 h-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="p-4 w-full mb-10">
              <div
                class="flex relative flex-col p-6 rounded-lg border border-gray-300 bg-gradient-to-r to-blue-100 from-gray-50"
              >
                <div
                  class="flex sm:flex-row flex-col sm:justify-between sm:items-start items-start"
                >
                  <h2
                    class="mb-1 text-sm font-medium tracking-widest title-font sm:mr-12 sm:ml-2"
                  >
                    Taxpayer
                  </h2>

                  <div class="relative w-full">
                    <input
                      @keypress.enter="loadPayerChart"
                      placeholder="Enter taxpayer name"
                      v-model="taxpayer"
                      class="h-8 w-full px-4 text-sm font-semibold bg-transparent rounded border border-gray-300"
                    />
                    <span class="text-xs text-gray-400"
                      >press enter to load chart</span
                    >
                  </div>
                </div>
                <div class="overflow-hidden overflow-x-auto">
                  <div
                    class="w-102 sm:w-full mt-8 shadow-sm overflow-scroll sm:overflow-hidden hide-scrollbar"
                  >
                    <apexchart
                      width="100%"
                      height="250"
                      type="area"
                      :options="singlePayerChart.options"
                      :series="singlePayerChart.series"
                    >
                    </apexchart>
                  </div>
                </div>
                <!-- <button
                  class="flex items-center w-full sm:w-auto px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
                >
                  Generate report
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="ml-auto w-4 h-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </content-holder>
    <content-holder
      v-else
      :title="'Revenue Summary'"
      class="absolute inset-0 p-6"
    >
      <unauthorized-access></unauthorized-access>
    </content-holder>

    <teleport to=".report-modal">
      <report-modal
        :date="formatDates()"
        :key="reportName"
        :reportName="reportName"
        :reportType="reportType"
        @close-modal="generateReportModal = false"
        v-show="generateReportModal"
        class="absolute inset-0 z-50"
      >
      </report-modal>
    </teleport>
  </div>
</template>

<script>
// import ApexCharts from "apexcharts";
import DatePicker from "@/components/DatePicker2.vue";
import ContentHolder from "@/components/ContentHolder.vue";
import AppDropDown from "@/components/shared/DropDown.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import AppSummary from "@/components/report/Summary.vue";
import ReportModal from "@/components/report/ReportModal.vue";
import { emitter } from "@/main";
import { views } from "@/helpers/login_details.js";
import { handleExcelExport } from "@/helpers/json_export.js";
// import moment from "moment";
import GlobalReport from "@/mixins/report.js";
import Swal from "sweetalert2";
// import moment from "moment";
export default {
  name: "Reports",
  mixins: [GlobalReport],
  components: {
    DatePicker,
    ContentHolder,
    UnauthorizedAccess,
    AppSummary,
    AppDropDown,
    ReportModal,
  },
  data: () => ({
    canViewReports: false,
    reportName: "",
    generateReportModal: false,
    showMdas: false,
    taxpayer: "",
    selectedMda: "",
    RevItems: [],
    Mdas: [],
    Banks: [],
    selectedBank: "",
    selectedItem: "",
    loadingReportsData: false,
    selectedDates: {
      startDate: new Date(),
      endDate: new Date(),
    },
    formattedDate: {},
    paymentsByChannel: {},
    dateUpdated: false,
    loadingDetailedReport: false,
    loadingChannelsReport: false,
    series: [
      {
        data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      labels: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
      legend: {
        show: false,
      },
    },
    exportingExcel: false,
  }),
  watch: {
    generateReportModal(val) {
      emitter.emit("hide_scroll", val);
    },
  },
  methods: {
    async exportToExcel() {
      try {
        this.exportingExcel = true;
        await handleExcelExport("topPayers", "list", "topPayers");
        // await handlePdfExport("list-topPayers");
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      } finally {
        this.exportingExcel = false;
      }
    },
    generateReport(type) {
      this.reportName = type;
      this.generateReportModal = true;
    },
    async generateDetailedReport() {
      try {
        this.loadingDetailedReport = true;
        await this.generateSpecialReport(
          this.reportType,
          [],
          "Detailed",
          this.formatDates()
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDetailedReport = false;
      }
    },
    async generateChannelsReport() {
      try {
        this.loadingChannelsReport = true;
        await this.generateSpecialReport(
          this.reportType,
          [],
          "Payment Channel",
          this.formatDates()
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingChannelsReport = false;
      }
    },
    async update() {
      this.loadingReportsData = true;

      this.$store
        .dispatch("report/getSummaryData", {
          ...this.formatDates(),
          reportType: this.reportType,
        })
        .then(() => {
          this.getMonthsReportData();
          this.getBanksReportData();
          this.getMDAsReportData();
          this.getRevenueItemsReportData();
          this.getChannelsReportData();
          this.getTopPayersByFilter({
            ...this.formatDates(),
            reportType: this.reportType,
            channel: "All",
            limit: this.topPayersLimit,
            page: 1,
          });
          this.getSingleMdaReportData(this.selectedMda);
          this.getSingleBankReportData(this.selectedBank);
          this.getSingleItemReportData(this.selectedItem);

          if (this.taxpayer.length > 0)
            this.getSinglePayerReportData(this.taxpayer);
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingReportsData = false));
    },
    loadPayerChart() {
      this.getSinglePayerReportData(this.taxpayer);
    },
    selectMda(mda) {
      this.selectedMda = mda;
      this.getSingleMdaReportData(this.selectedMda);
    },
    selectBank(bank) {
      this.selectedBank = bank;
      this.getSingleBankReportData(this.selectedBank);
    },
    selectItem(item) {
      this.selectedItem = item.revenue_item;
      this.getSingleItemReportData(this.selectedItem);
    },
  },
  async created() {
    try {
      if (views.includes("Reports")) {
        this.canViewReports = true;
        this.Mdas = await this.$store.dispatch(
          "fetchItemsToFilter",
          "Revenue Category"
        );
        this.Banks = await this.$store.dispatch("fetchItemsToFilter", "Bank");
        this.RevItems = await this.$store.dispatch(
          "fetchItemsToFilter",
          "Revenue Item"
        );

        this.selectedMda = this.Mdas[0];
        this.selectedBank = this.Banks[0];
        this.selectedItem = this.RevItems[0].revenue_item;
        await this.getSingleMdaReportData(this.selectedMda);
        await this.getSingleBankReportData(this.selectedBank);
        await this.getSingleItemReportData(this.selectedItem);
      } else {
        this.canViewReports = false;
      }
    } catch (error) {
      console.log(error);
    }
  },
  deactivated() {
    this.$store.dispatch("report/updateOtherRevenues", []);
  },
};
</script>
