<template>
  <div
    class="animate__animated animate__fadeIn grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-4 border-b border-gray-100 pb-8"
  >
    <transition-group name="list">
      <div
        v-for="(summary, i) in summaryData"
        :key="i"
        class="group cursor-pointer p-3.5 transition-shadow border rounded-lg shadow-sm hover:shadow-lg"
      >
        <div class="w-full flex items-start justify-between">
          <div class="w-full flex flex-col">
            <div
              class="w-full flex items-center justify-between border-b border-dashed mb-2"
            >
              <span class="text-gray-400 font-medium" :key="reportType"
                >{{ summary.channel }} {{ reportType }}s
              </span>
              <span class="text-gray-400 text-xs font-medium"
                >({{ summary.data.currYear.Txn }})</span
              >
            </div>
            <div class="w-full flex items-center justify-between">
              <span class="text-lg font-semibold"
                >₦{{ formatAmount(summary.data.currYear.Amount) }}
              </span>
              <span class="hidden text-gray-400 text-xs font-medium"
                >({{ summary.data.currYear.Txn }})</span
              >
            </div>

            <!-- <span class="text-md text-gray-400 font-medium"
            >({{ formatNumber(summary.data.currYear.Txn) }}

            {{
              summary.data.currYear.Txn == 1 ? "transaction" : "transactions"
            }})</span
          > -->
          </div>
        </div>
        <div>
          <span
            class="inline-block px-2 text-sm text-white rounded mr-1"
            :class="`${
              getPercentageDifference(
                summary.data.currYear.Amount,
                summary.data.prevYear.Amount
              ).state === 'gain'
                ? 'bg-green-400'
                : 'bg-red-400'
            }`"
            >{{
              getPercentageDifference(
                summary.data.currYear.Amount,
                summary.data.prevYear.Amount
              ).result
            }}%</span
          >
          <i
            v-if="
              getPercentageDifference(
                summary.data.currYear.Amount,
                summary.data.prevYear.Amount
              ).state !== 'gain'
            "
            class="fas fa-arrow-down text-xs mr-2 text-red-400"
          ></i>
          <i v-else class="fas fa-arrow-up text-xs mr-2 text-green-400"></i>
          <span class="text-gray-500">from last year.</span>
        </div>
        <span
          class="text-xs font-bold text-gray-50"
          :class="`  ${
            getPercentageDifference(
              summary.data.currYear.Amount,
              summary.data.prevYear.Amount
            ).state === 'gain'
              ? 'text-green-400'
              : 'text-red-400'
          } `"
          >prevYear - ₦{{ formatAmount(summary.data.prevYear.Amount) }}</span
        >
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import numeral from "numeral";
export default {
  name: "Summary",
  props: ["date", "type"],
  data: () => ({
    filterDate: {},
  }),
  computed: {
    ...mapGetters({ summaryData: ["report/summaryData"] }),
    reportType() {
      if (this.type.toLowerCase() == "detailed") return "settlement";
      else return this.type;
    },
  },
  methods: {
    ...mapActions({ getSummaryData: ["report/getSummaryData"] }),
    formatDates() {
      this.filterDate.startDate = moment(this.date.startDate).format(
        "YYYY-MM-DD"
      );
      this.filterDate.endDate = moment(this.date.endDate).format("YYYY-MM-DD");

      return this.filterDate;
    },
    formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    },
    formatNumber(num) {
      return numeral(num).format("0,0");
    },
    getPercentageDifference(newVal, oldVal) {
      let result = null;
      let state = "";
      let diff = 0;

      if (newVal > oldVal) {
        diff = newVal - oldVal;
        state = "gain";
      } else {
        diff = oldVal - newVal;
        state = "loss";
      }

      if (diff > 0 && oldVal > 0) {
        result = parseInt((diff / oldVal) * 100);
        if (result > 100) result = 100;
      } else if (diff > 0 && oldVal == 0) result = 100;
      else result = 0;

      return { result, state };
    },
    async search() {
      try {
        let filter = {};
        filter.startDate = moment(filter.startDate).format("YYYY-MM-DD");
        filter.endDate = moment(filter.endDate).format("YYYY-MM-DD");
        filter.assessment_type = "all";

        await this.$store.dispatch("assessment/fetchAssessmentsFromDB", filter);
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.$store.commit("report/SUMMARY_DATA", []);
    this.$store
      .dispatch("report/getSummaryData", {
        ...this.formatDates(),
        reportType: this.reportType,
      })
      .then(() => {})
      .catch((err) => console.log(err));
  },
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0px;
  transform: translateX(-50px);
}
</style>
