import moment from "moment";
import numeral from "numeral";
import ApexCharts from "apexcharts";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    reportType: "payment",
    monthsChart: {
      series: [
        {
          name: "Amount",
          type: "bar",
          data: [0],
        },
        {
          name: "Amount",
          type: "line",
          data: [0],
        },
      ],
      options: {
        chart: {
          type: "bar",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 500,
            },
          },
          xaxis: {
            categories: [0],
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: [0, 4],
        },
        title: {
          text: "Collections by Month",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },

        labels: [""],
        legend: {
          position: "top",
        },
      },
    },
    banksChart: {
      series: [
        {
          type: "bar",
          data: [0],
        },
      ],
      options: {
        chart: {
          type: "bar",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 500,
            },
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Collections by Bank",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },

        labels: [""],
        legend: {
          position: "top",
        },
        theme: {
          palette: "palette5", // upto palette10
        },
      },
    },
    mdaChart: {
      series: [
        {
          type: "bar",
          data: [0],
        },
      ],
      options: {
        chart: {
          type: "bar",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 500,
            },
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Collections by Revenue Category",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },

        labels: [""],
        legend: {
          position: "top",
        },
        theme: {
          palette: "palette3", // upto palette10
        },
      },
    },
    revenueItemChart: {
      series: [
        {
          type: "bar",
          data: [0],
        },
      ],
      options: {
        chart: {
          type: "bar",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 500,
            },
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Collections by Revenue Item",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },

        labels: [""],
        legend: {
          position: "top",
        },
        theme: {
          palette: "palette2", // upto palette10
        },
      },
    },
    taxpayerChart: {},
    channelsChart: {
      obj: {},
      series: [],
      options: {
        chart: {
          type: "donut",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Collections by Channel",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },
        labels: [],
        legend: {
          position: "right",
        },
      },
    },
    singleBankChart: {
      series: [
        {
          name: "Amount",
          type: "column",
          data: [0],
        },
        {
          name: "Amount",
          type: "line",
          data: [0],
        },
      ],
      options: {
        chart: {
          type: "line",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 500,
            },
          },
          xaxis: {
            categories: [0],
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: [0, 4],
        },
        title: {
          text: "",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },

        labels: [""],
        legend: {
          position: "top",
        },
      },
    },
    singleItemChart: {
      series: [
        {
          name: "Amount",
          type: "column",
          data: [0],
        },
        {
          name: "Amount",
          type: "line",
          data: [0],
        },
      ],
      options: {
        chart: {
          type: "line",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 500,
            },
          },
          xaxis: {
            categories: [0],
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: [0, 4],
        },
        title: {
          text: "",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },

        labels: [""],
        legend: {
          position: "top",
        },
      },
    },
    singlePayerChart: {
      series: [
        {
          name: "Amount",
          type: "column",
          data: [0],
        },
        {
          name: "Amount",
          type: "line",
          data: [0],
        },
      ],
      options: {
        chart: {
          type: "line",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 500,
            },
          },
          xaxis: {
            categories: [0],
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: [0, 4],
        },
        title: {
          text: "",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },

        labels: [""],
        legend: {
          position: "top",
        },
      },
    },
    singleMdaChart: {
      series: [
        {
          name: "Amount",
          type: "column",
          data: [0],
        },
        {
          name: "Amount",
          type: "line",
          data: [0],
        },
      ],
      options: {
        chart: {
          type: "line",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 500,
            },
          },
          xaxis: {
            categories: [0],
          },
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: [0, 4],
        },
        title: {
          text: "",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "₦" + numeral(value).format("0,0.00");
            },
          },
        },

        labels: [""],
        legend: {
          position: "top",
        },
      },
    },
    topPayers: [],
    topPayersLimit: 5,
  }),
  watch: {
    topPayersLimit(val) {
      console.log(val);
      this.getTopPayersByFilter({
        reportType: this.reportType,
        ...this.formatDates(),
        channel: "All",
        limit: val,
        page: 1,
      });
    },
  },

  computed: {
    ...mapGetters(["getTopPayers"]),
    topPayersWithLimit() {
      let payers = [];

      if (this.getTopPayers)
        for (var p = 0; p < this.getTopPayers.length; p++) {
          if (p < this.topPayersLimit) payers.push(this.getTopPayers[p]);
        }
      return payers;
    },
  },
  methods: {
    ...mapActions(["getTopPayersByFilter"]),
    async generateSpecialReport(type, revenues, name, date) {
      try {
        let payload = {
          reportType: type,
          otherRevenues: revenues,
          reportName: name,
          ...date,
        };
        let result = await this.$store.dispatch(
          "report/generateSpecialRevenueReport",
          payload
        );
        await window.open(
          URL.createObjectURL(
            new Blob([result.data], { type: "application/pdf" })
          ),
          "_blank"
        );

        // When the button is clicked, I replaced window.open() with the following codes
        // var menuAddress = URL.createObjectURL(
        //   new Blob([result.data], { type: "application/pdf" })
        // );
        //window.open(menuAddress);

        // Thanks https://stackoverflow.com/a/44487883
        // var link = document.getElementById("link");
        // link.setAttribute("href", menuAddress);
        // link.click();

        return true;
      } catch (error) {
        return false;
      }
    },
    formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    },
    updateStartDate(newDate) {
      this.selectedDates.startDate = newDate;
    },
    updateEndDate(newDate) {
      this.selectedDates.endDate = newDate;
      this.dateUpdated = true;
    },
    formatDates() {
      this.formattedDate.startDate = moment(
        this.selectedDates.startDate
      ).format("YYYY-MM-DD");
      this.formattedDate.endDate = moment(this.selectedDates.endDate).format(
        "YYYY-MM-DD"
      );

      return this.formattedDate;
    },
    updateMonthsChart() {
      const result = this.$store.getters["report/monthReportData"];
      let series = [];
      let labels = [];

      result.sort((a, b) => {
        var dateA = new Date(a.Month),
          dateB = new Date(b.Month);
        return dateA - dateB;
      });

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        labels.push(res.Month);
      });

      this.monthsChart.series = [{ data: series }, { data: series }];
      this.monthsChart.options = {
        ...this.monthsChart.options,
        labels: labels,
      };
    },
    updateSingleMdaChart() {
      const result = this.$store.getters["report/singleMdaReportData"];
      let series = [];
      let labels = [];

      result.sort((a, b) => {
        var dateA = new Date(a.Month),
          dateB = new Date(b.Month);
        return dateA - dateB;
      });

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        labels.push(res.Month);
      });

      this.singleMdaChart.series = [{ data: series }, { data: series }];
      this.singleMdaChart.options = {
        ...this.singleMdaChart.options,
        labels: labels,
      };
    },
    updateSingleBankChart() {
      const result = this.$store.getters["report/singleBankReportData"];
      let series = [];
      let labels = [];

      result.sort((a, b) => {
        var dateA = new Date(a.Month),
          dateB = new Date(b.Month);
        return dateA - dateB;
      });

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        labels.push(res.Month);
      });

      this.singleBankChart.series = [{ data: series }, { data: series }];
      this.singleBankChart.options = {
        ...this.singleBankChart.options,
        labels: labels,
      };
    },
    updateSingleItemChart() {
      const result = this.$store.getters["report/singleItemReportData"];
      let series = [];
      let labels = [];

      result.sort((a, b) => {
        var dateA = new Date(a.Month),
          dateB = new Date(b.Month);
        return dateA - dateB;
      });

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        labels.push(res.Month);
      });

      this.singleItemChart.series = [{ data: series }, { data: series }];
      this.singleItemChart.options = {
        ...this.singleItemChart.options,
        labels: labels,
      };
    },
    updateSinglePayerChart() {
      const result = this.$store.getters["report/singlePayerReportData"];
      let series = [];
      let labels = [];

      result.sort((a, b) => {
        var dateA = new Date(a.Month),
          dateB = new Date(b.Month);
        return dateA - dateB;
      });

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        labels.push(res.Month);
      });

      this.singlePayerChart.series = [{ data: series }, { data: series }];
      this.singlePayerChart.options = {
        ...this.singlePayerChart.options,
        labels: labels,
      };
    },
    getMonthsReportData() {
      this.$store
        .dispatch("report/getMonthsChartData", {
          reportType: this.reportType,
          ...this.formatDates(),
        })
        .then(() => {
          this.updateMonthsChart();
        })
        .catch((err) => console.log(err));
    },
    updateBanksChart() {
      const result = this.$store.getters["report/bankReportData"];
      let series = [];
      let labels = [];

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        labels.push(
          res.item.length > 20 ? res.item.substr(0, 20) + "..." : res.item
        );
      });

      this.banksChart.series = [{ data: series }];
      this.banksChart.options = {
        ...this.banksChart.options,
        labels: labels,
      };
    },
    getBanksReportData() {
      this.$store
        .dispatch("report/getBanksChartData", {
          reportType: this.reportType,
          ...this.formatDates(),
        })
        .then(() => {
          this.updateBanksChart();
        })
        .catch((err) => console.log(err));
    },
    getMDAsReportData() {
      this.$store
        .dispatch("report/getMDAsChartData", {
          reportType: this.reportType,
          ...this.formatDates(),
        })
        .then(() => {
          this.updateMDAsChart();
        })
        .catch((err) => console.log(err));
    },
    updateMDAsChart() {
      const result = this.$store.getters["report/mdaReportData"];
      let series = [];
      let labels = [];

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        labels.push(
          res.item.length > 20 ? res.item.substr(0, 20) + "..." : res.item
        );
      });

      this.mdaChart.series = [{ data: series }];
      this.mdaChart.options = {
        ...this.mdaChart.options,
        labels: labels,
      };
    },
    getRevenueItemsReportData() {
      this.$store
        .dispatch("report/getRevenueItemsChartData", {
          reportType: this.reportType,
          ...this.formatDates(),
        })
        .then(() => {
          this.updateRevenueItemsChart();
        })
        .catch((err) => console.log(err));
    },
    updateRevenueItemsChart() {
      const result = this.$store.getters["report/revenueItemReportData"];
      let series = [];
      let labels = [];

      result.forEach((res) => {
        series.push(Math.round(res.Amount));
        labels.push(
          res.item.length > 20 ? res.item.substr(0, 23) + "..." : res.item
        );
      });

      this.revenueItemChart.series = [{ data: series }];
      this.revenueItemChart.options = {
        ...this.revenueItemChart.options,
        labels: labels,
      };
    },
    updateChannelsChart() {
      const result = this.$store.getters["report/channelReportData"];
      let series = [];
      let labels = [];

      result.forEach((res) => {
        series.push(Math.round(res.data.currYear.Amount));
        labels.push(
          res.channel.length > 20
            ? res.channel.substr(0, 20) + "..."
            : res.channel
        );
      });

      this.channelsChart.obj.updateSeries(series, true);
      this.channelsChart.obj.updateOptions(
        {
          labels: labels,
        },
        true
      );
    },
    getChannelsReportData() {
      this.$store
        .dispatch("report/getChannelsChartData", {
          reportType: this.reportType,
          ...this.formatDates(),
        })
        .then(() => {
          this.updateChannelsChart();
        })
        .catch((err) => console.log(err));
    },
    getSingleMdaReportData(item) {
      this.$store
        .dispatch("report/getMonthlyPaymentsByItem", {
          reportType: this.reportType,
          item,
          filterColumn: "revenue_category",
          ...this.formatDates(),
        })
        .then(() => {
          this.updateSingleMdaChart();
        })
        .catch((err) => console.log(err));
    },
    getSingleBankReportData(item) {
      this.$store
        .dispatch("report/getMonthlyPaymentsByItem", {
          reportType: this.reportType,
          item,
          filterColumn: "bank",
          ...this.formatDates(),
        })
        .then(() => {
          this.updateSingleBankChart();
        })
        .catch((err) => console.log(err));
    },
    getSingleItemReportData(item) {
      this.$store
        .dispatch("report/getMonthlyPaymentsByItem", {
          reportType: this.reportType,
          item,
          filterColumn: "revenue_item",
          ...this.formatDates(),
        })
        .then(() => {
          this.updateSingleItemChart();
        })
        .catch((err) => console.log(err));
    },
    getSinglePayerReportData(item) {
      this.$store
        .dispatch("report/getMonthlyPaymentsByItem", {
          reportType: this.reportType,
          item,
          filterColumn: "customer_name",
          ...this.formatDates(),
        })
        .then(() => {
          this.updateSinglePayerChart();
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    try {
      this.channelsChart.obj = new ApexCharts(
        document.querySelector(".channels_chart"),
        this.channelsChart.options
      );
      this.getMonthsReportData();
      this.getBanksReportData();
      this.getChannelsReportData();
      this.getRevenueItemsReportData();
      this.getMDAsReportData();
      this.getTopPayersByFilter({
        reportType: this.reportType,
        ...this.formatDates(),
        channel: "All",
        limit: this.topPayersLimit,
        page: 1,
      });
      this.channelsChart.obj.render();
    } catch (error) {
      console.log(error);
    }
  },
};
