import XLSX from "xlsx";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";

export async function handleExcelExport(id, sheet, name) {
  try {
    var elt = document.getElementById(id);
    var wb = XLSX.utils.table_to_book(elt, { sheet: sheet, raw: true });

    return await XLSX.writeFile(wb, `${name}-${Date.now()}.` + "xlsx");
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  }
}
export async function handlePdfExport(id) {
  try {
    var doc = new jsPDF();
    await doc.html(document.getElementById(id), {
      callback: function (doc) {
        doc.save();
      },
      x: 10,
      y: 10,
    });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  }
}
