<template>
  <div class="relative">
    <div class="h-screen w-full z-50 inset-0 overflow-y-auto">
      <div class="absolute w-full h-full inset-0 bg-gray-500 opacity-75"></div>
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
        </span>
        <div
          class="animate__animated animate__zoomIn animate__faster inline-block relative overflow-hidden transform transition-all sm:align-middle sm:max-w-lg"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div class="rounded-lg p-4 bg-white shadow">
              <div class="bg-white dark:bg-gray-800">
                <div class="w-full flex items-center justify-between pl-8">
                  <p class="text-lg text-gray-600">
                    Other Revenue Items for {{ reportName }}
                  </p>
                  <!-- <i  class="far fa-times-circle text-red-400 text-2xl font-bold cursor-pointer"></i> -->
                </div>
                <div
                  class="text-left w-full mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8 z-20"
                >
                  <label
                    for="channels"
                    class="block leading-7 text-sm text-gray-dark dark:text-white"
                    >Select channel</label
                  >
                  <select
                    id="channels"
                    v-model="reportChannel"
                    class="bg-white mb-4 border-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="all">All Channels</option>
                    <option value="bank">Bank</option>
                    <option value="pos">POS</option>
                    <option value="internet">Internet</option>
                    <option value="ifis">IFIS</option>
                  </select>

                  <div
                    class="w-full sm:flex sm:items-end sm:space-x-4 relative sm:mb-0"
                  >
                    <div class="w-full flex flex-col items-start">
                      <label
                        for="amount"
                        class="leading-7 text-sm text-gray-dark"
                        >Item name</label
                      >
                      <input
                        type="text"
                        id="itemName"
                        ref="itemName"
                        placeholder="revenue item name"
                        v-model="itemName"
                        autocomplete="off"
                        class="w-full bg-white rounded-lg border-2 border-gray-300 focus:border-gray-600 focus:ring-2 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                    </div>
                    <div class="w-full flex flex-col items-start">
                      <label
                        for="amount"
                        class="leading-7 text-sm text-gray-dark"
                        >Amount</label
                      >
                      <input
                        type="number"
                        id="itemAmount"
                        name="itemAmount"
                        v-model="itemAmount"
                        @keyup.enter="addItemToList"
                        autocomplete="off"
                        class="w-full bg-white rounded-lg border-2 border-gray-300 focus:border-gray-600 focus:ring-2 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                    </div>

                    <button
                      @click.prevent="addItemToList"
                      class="w-full sm:w-auto mt-4 sm:mt-0 block transition duration-300 ease-in-out focus:outline-none bg-gray-600 text-white text-md text-center py-2.5 px-4 cursor-pointer rounded-lg hover:bg-gray-700"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                  <div
                    class="h-56 px-0.5 py-2 overflow-y-auto hide-scrollbar mt-4 lg:flex-shrink-0"
                  >
                    <div
                      class="container flex flex-col mx-auto w-full items-start justify-between bg-white dark:bg-gray-800 rounded-lg shadow"
                    >
                      <ul class="w-full flex flex-col divide divide-y">
                        <li v-for="(item, index) in otherRevenues" :key="index">
                          <div
                            class="select-none cursor-pointer flex flex-row items-center justify-start p-4"
                          >
                            <span>{{ index + 1 }}.</span>
                            <div
                              class="w-4/5 ml-4 sm:flex sm:flex-row flex-col sm:items-center justify-start"
                            >
                              <div class="w-2/3 flex-1 sm:pl-1">
                                <div
                                  class="font-medium dark:text-white text-left"
                                >
                                  {{ item.item }}
                                </div>
                              </div>
                              <div
                                class="w-full text-gray-600 dark:text-gray-200 text-xs sm:text-right"
                              >
                                ₦ {{ formatAmount(item.amount) }}
                              </div>
                            </div>

                            <button class="w-24 text-right flex justify-end">
                              <i
                                @click="removeItem(item, index)"
                                class="far fa-times-circle text-red-400 text-xl font-bold cursor-pointer"
                              ></i>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="flex items-center justify-between gap-4 w-full mt-8"
                  >
                    <button
                      @click="generateSpecialReport"
                      :disabled="generating"
                      type="button"
                      class="py-2 px-4 bg-primary hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                    >
                      <div v-if="!generating">Generate</div>
                      <div
                        v-else
                        class="flex items-center space-x-4 justify-center"
                      >
                        <i class="fas fa-circle-notch animate-spin"></i>
                        <span class="hidden sm:block">Please wait...</span>
                      </div>
                    </button>
                    <button
                      @click="closeModal"
                      type="button"
                      class="py-2 px-4 bg-white hover:bg-gray-100 focus:ring-red-500 focus:ring-offset-red-200 text-red-500 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import numeral from "numeral";

export default {
  props: ["reportName", "date", "reportType"],
  emits: ["close-modal"],
  data: () => ({
    generating: false,
    reportChannel: "all",
    itemAmount: 0,
    itemName: "",
    revenues: [],
  }),
  computed: {
    otherRevenues() {
      return this.$store.getters["report/otherRevenues"];
    },
  },
  methods: {
    formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    },
    closeModal() {
      this.revenues = [];
      this.$emit("close-modal");
    },
    addItemToList() {
      if ((this.itemAmount > 0) & (this.itemName.length > 3)) {
        this.revenues.push({ item: this.itemName, amount: this.itemAmount });
        this.itemName = "";
        this.itemAmount = 0;
        this.$refs.itemName.focus();

        this.$store.dispatch("report/updateOtherRevenues", this.revenues);
      }
    },
    removeItem(item, index) {
      this.revenues.splice(index, 1);
      this.$store.dispatch("report/updateOtherRevenues", this.revenues);
    },
    async generateSpecialReport() {
      try {
        this.generating = true;

        let payload = {
          reportType: this.reportType,
          otherRevenues: this.revenues,
          reportName: this.reportName,
          channel: this.reportChannel,
          ...this.date,
        };
        let result = await this.$store.dispatch(
          "report/generateSpecialRevenueReport",
          payload
        );
        await window.open(
          URL.createObjectURL(
            new Blob([result.data], { type: "application/pdf" })
          ),
          "_blank"
        );
        this.generating = false;

        this.$emit("close-modal");
      } catch (error) {
        this.generating = false;
      }
    },
  },
  created() {
    this.revenues = this.$store.getters["report/otherRevenues"];
  },
};
</script>
